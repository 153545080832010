import React from 'react';
import './sidebar.scss';
import Logo from '../../assets/logo.svg';
import { NavLink, useLocation } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { Box, Divider, Flex, Img, Text } from '@chakra-ui/react';
import { useState } from 'react';

const Sidebar = ({navOpen}) => {
    const currentUser = useSelector(({userData}) => userData.currentUser)
    console.log(currentUser?.data)
    const { userClass } = currentUser?.data
    const { pathname } = useLocation();

    const [showUsers, setShowUsers] = useState(false);
    const [showConstants, setShowConstants] = useState(false);
    return (
        <div className="side-bar" id={navOpen ? 'enter' : 'collapse'}>
            <Flex justifyContent="center" className="logo">
                <Img src={Logo} alt="" />
            </Flex>

            <div className="nav">

                
                <NavLink activeClassName='active' className='list-item' exact to='/dashboard'><i className="fas fa-home"></i><span className='label'>Dashboard</span></NavLink>
                <NavLink isActive={() => ['/dashboard/request/make-request', '/dashboard/request/drivers', '/dashboard/request'].includes(pathname)} activeClassName='active' className='list-item' exact to='/dashboard/request'><i className="fas fa-chalkboard-teacher"></i><span className='label'>Request</span></NavLink>
                <NavLink isActive={() => ['/dashboard/trips', '/dashboard/trips/tripId'].includes(pathname)} activeClassName='active' className='list-item' exact to='/dashboard/trips'><i style={{marginLeft: '3px', marginRight: '23px'}} className="fas fa-map-marker-alt"></i><span className='label'>Trips</span></NavLink>
                <NavLink activeClassName='active' className='list-item' exact to='/dashboard/leads'><i style={{marginRight: '23px'}} className="far fa-user"></i><span className='label'>Leads</span></NavLink>
                <NavLink activeClassName='active' className='list-item' exact to='/dashboard/live-tracking'><i style={{marginRight: '23px'}} className="fas fa-location-arrow"></i><span className='label'>Live Tracking</span></NavLink>
                <NavLink activeClassName='active' className='list-item' exact to='/dashboard/sms'><i className="fas fa-envelope"></i><span className='label'>SMS</span></NavLink>
                <NavLink activeClassName='active' className='list-item' exact to='/dashboard/offline'><i className="fas fa-home"></i><span className='label'>Offline Drivers</span></NavLink>

                <Box cursor={"pointer"} onClick={() => setShowUsers(!showUsers)} className='list-item'>
                        <i className="fas fa-user"></i><span className='label'>Users</span>
                        <i style={{marginLeft: "3rem", transform: "translateY(3px)"}} className="fas fa-angle-down"></i>
                </Box>
                <Divider />

                {showUsers && 
                <Box ml={8}>
                    <NavLink activeClassName='active' className='list-item' exact to='/dashboard/drivers'><i className="fas fa-user-tie"></i><span className='label'>Drivers</span></NavLink>
                    <NavLink activeClassName='active' className='list-item' exact to='/dashboard/customers'><i className="fas fa-user-tie"></i><span className='label'>Customers</span></NavLink>
                    <NavLink activeClassName='active' className='list-item' exact to='/dashboard/enterprise'><i className="fas fa-user-tie"></i><span className='label'>Enterprise</span></NavLink>
                    <NavLink activeClassName='active' className='list-item' exact to='/dashboard/merchant'><i className="fas fa-user-tie"></i><span className='label'>Merchant</span></NavLink>
                </Box>}
               
                <NavLink activeClassName='active' className='list-item' exact to='/dashboard/finance'><i style={{marginRight: '23px'}} className="fas fa-wallet"></i><span className='label'>Finance</span></NavLink>

                <Box cursor={"pointer"} onClick={() => setShowConstants(!showConstants)} className='list-item'>
                        <i className="fas fa-circle"></i><span className='label'>App Data</span>
                        <i style={{marginLeft: "2rem", transform: "translateY(3px)"}} className="fas fa-angle-down"></i>
                </Box>
                <Divider />

                {showConstants && 
                <Box ml={8}>
                    <NavLink activeClassName='active' className='list-item' exact to='/dashboard/vehicles'><i style={{marginRight: '23px'}} className="far fa-bell"></i><span className='label'>Vehicles</span></NavLink>
                    <NavLink activeClassName='active' className='list-item' exact to='/dashboard/countries'><i style={{marginRight: '23px'}} className="far fa-flag"></i><span className='label'>Countries</span></NavLink>
                    <NavLink activeClassName='active' className='list-item' exact to='/dashboard/referrals'><i style={{marginLeft: '3px', marginRight: '23px'}} className="fas fa-list"></i><span className='label'>Referrals</span></NavLink>
                    <NavLink activeClassName='active' className='list-item' exact to='/dashboard/routes'><i style={{marginRight: '23px'}} className="fas fa-route"></i><span className='label'>Routes</span></NavLink>
                </Box>}

               
                <NavLink activeClassName='active' className='list-item' exact to='/dashboard/ticket'><i style={{marginRight: '23px'}} className="fas fa-headset"></i><span className='label'>Support</span></NavLink>
                <NavLink activeClassName='active' className='list-item' exact to='/dashboard/coupon'><i style={{marginRight: '23px'}} className="fas fa-star"></i><span className='label'>Coupon</span></NavLink>
                {userClass === "superAdmin" && <NavLink activeClassName='active' className='list-item' exact to='/dashboard/admins'><i className="fas fa-user-tie"></i><span className='label'>Admins</span></NavLink>}
                {userClass === "superAdmin" && <NavLink activeClassName='active' className='list-item' exact to='/dashboard/settings'><i style={{marginRight: '23px'}} className="fas fa-cog"></i><span className='label'>Settings</span></NavLink>}
            </div>
           
        </div>
    )
}

export default Sidebar;