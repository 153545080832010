import { baseUrl } from '../config.json';
import axios from 'axios';

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
       }
 }

export async function getTripAnalytics() {
    return axios.get(`${baseUrl}/admin/trip/trip-analytics`, config);
}

export async function getAllTrips() {
    return axios.get(`${baseUrl}/admin/trip/retrieve-trips`, config)
}

export async function getAllCoupons() {
    return axios.get(`${baseUrl}/admin/coupon/all`, config)
}

export async function getTripDetails(id) {
    return axios.get(`${baseUrl}/admin/trip/${id}/trip-details`, config)
}