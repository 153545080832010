import React, { useRef, useState } from "react";
// import "./allRequests.scss";
import { useQuery } from "react-query";
import { cancelRequest, getAllRequests } from "../../../../services/requestServices";
import DeleteModal from "../../../deleteModal/deleteModal";
import EmptyState from "../../../emptyState/emptyState";
import { toast } from "react-toastify";
import axios from "axios";
import { baseUrl, devUrl } from '../../../../config.json';
import { Link } from "react-router-dom";
import { useHistory } from "react-router";
import RequestView from "../request-view/requestView";
import {
  Avatar,
  Badge,
  ChakraProvider,
  Image,
  Menu,
  MenuButton,
  MenuDivider,
  MenuItem,
  MenuList,
  Spinner,
  Table,
  TableContainer,
  Tbody,
  Td,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import {
  Modal,
  ModalOverlay,
  ModalContent,
  ModalHeader,
  ModalFooter,
  ModalBody,
  ModalCloseButton,
  useDisclosure,
  Button,
  Flex,
  Box,
  Text,
  Divider,
  AvatarBadge
} from '@chakra-ui/react';
import { jsPDF } from "jspdf";
import html2canvas from "html2canvas";
import Logo from './wehaulogo.png';
import PaginatedTable from "../../../paginatedTable/paginatedTable";
import Dots from './dots.svg';
import Pagination from "../../../pagination/pagination";

const AllRequests = ({status}) => {
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [clickedId, setClickedId] = useState("");
  const [requestViewShown, setRequestViewShown] = useState(false);
  const [requestId, setRequestId] = useState("");

  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage, setPostsPerPage] = useState(20)

  const [cloading, setCloading] = useState(false)

  const history = useHistory();

  const { data, error, isLoading } = useQuery("getTrips", getAllRequests);
  if (isLoading) {
    console.log("data is loading");
  } else if (data) {
    console.log("getRequests", data.data.data);
  } else {
    console.log(error);
  }

  const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
       }
  }

  const getRequestId = async (id) => {
    console.log(id);
    await setRequestId(id);
    setRequestViewShown(true);
  };


  const handleCancel = (id) => {
     setClickedId(id)
     setShowDeleteModal(true)
  }

  const formData = {
    lowerBoundPrice: 10000
  }

  const cancelRequest = async () => {
    setCloading(true)
    try {
       const res = await axios.put(`${baseUrl}/admin/request/${clickedId}/cancel-request`, formData, config);
       toast.success(res.data.message, {
        position: toast.POSITION.TOP_CENTER
     })
         console.log(res.data.message)
         setCloading(false)
        //  history.push('/dashboard/request')
       window.location.reload()
     } catch(error) {
        if(error) {
           // console.log('error', error.response.data.message)
           toast.error(error.response.data.message, {
              position: toast.POSITION.TOP_CENTER
           })
        }
        setCloading(false)
     }
 }
 const { isOpen, onOpen, onClose } = useDisclosure()

 const [receiptLoader, setReceptLoader] = useState(true);
  const [receiptData, setReceptData] = useState(null);

  const getTripIdx = async(id) => {
    await setRequestId(id);
    await onOpen()
    // console.log("workcc",id);
    getReceiptDetails(id)
  };

  const getReceiptDetails = async(id) => {
    try {
      const {data} = await axios.get(`${devUrl}/admin/request/${id}/request-details`, config)
      // console.log(data?.data)
      setReceptData(data?.data)
      setReceptLoader(false)
    } catch(err) {
      console.log(err?.response?.data?.message)
    }
}

const inputRef = useRef(null);
  const printDocument = () => {
    html2canvas(inputRef.current).then((canvas) => {
      const imgData = canvas.toDataURL("image/png");
      const pdf = new jsPDF();
      pdf.addImage(imgData, "JPEG", 0, 0);
      pdf.save("wehaul-trip-receipt.pdf");
    });
  };

  const indexOfLastPost = currentPage * postsPerPage;
  const indexOfFirstPost = indexOfLastPost - postsPerPage; 
  const currentPosts = data?.data?.data.slice(indexOfFirstPost, indexOfLastPost);

 const rowTitles = [
  "Customer",
  "Phone Number",
  "Pick-up",
  "Drop-off",
  "Vehicle",
  "Receiver",
  "Pickup Date",
  "Status",
  "Action"
]

const tableBody = currentPosts?.map(
  (
    {
      pickUpAddress,
      destinationAddress,
      vehicleType,
      receiverDetails,
      pickUpDate,
      requestStatus,
      estPrice,
      qualifiedBikeRiders,
      createdBy,
      responseCount,
      _id: id
    },
    index
  ) => {
    return (
      <Tr className="content-row" key={index}>
        <Td className="lefty pre">{createdBy?.name}</Td>
        <Td className="lefty pre">{createdBy?.phoneNumber}</Td>
        <Td className="lefty pre">{pickUpAddress?.slice(0,40)}..</Td>
        <Td className="lefty">{destinationAddress?.slice(0,40)}..</Td>
        <Td>{vehicleType}</Td>
        <Td>{receiverDetails.name}</Td>
        <Td>{pickUpDate?.slice(0,10)}</Td>
        {requestStatus === "active" && (
          <Td>
            {/* <p className="active-status">{requestStatus}</p> */}
            <Badge colorScheme={"green"}>{requestStatus}</Badge>
          </Td>
        )}
        {requestStatus === "assigned" && (
          <Td>
            <Badge colorScheme={"blue"}>{requestStatus}</Badge>
          </Td>
        )}
        {requestStatus === "rejected" && (
          <Td>
            <p className="cancelled-status">{requestStatus}</p>
          </Td>
        )}
        {requestStatus === "cancelled" && (
          <Td>
            <Badge colorScheme={"red"}>{requestStatus}</Badge>
          </Td>
        )}
        <Td>
          <Menu size={"xs"}>
            <MenuButton>
              <Image cursor={"pointer"} ml="1.5rem" src={Dots} />
            </MenuButton>
            <MenuList>
              <MenuItem onClick={() => getRequestId(id)}>View</MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => history.push('/dashboard/request/other-drivers', {reqId: id, userClass: createdBy?.userClass, userId: createdBy?.id})}>Drivers Responses<Badge ml={2} colorScheme={"blue"}>{responseCount}</Badge></MenuItem>
              {requestStatus === 'active' && 
              <>
              <MenuDivider />
              <MenuItem onClick={() => handleCancel(id)}>Cancel Request</MenuItem>
              <MenuDivider />
              <MenuItem onClick={() => getTripIdx(id)}>Generate Invoice <i style={{marginLeft: ".5rem"}} className="fas fa-download"></i></MenuItem>
              </>}
            </MenuList>
          </Menu>
          
          {/* <p onClick={() => getRequestId(id)} style={{ backgroundColor: "#113E82", color: "#fff", padding: "5px", borderRadius: "6px", marginBottom: "7px" }}>View</p>
          {vehicleType === 'bike' && requestStatus !== 'cancelled' && <Link to={{pathname: "/dashboard/request/bike-drivers",state: {reqId: id, reqData: qualifiedBikeRiders}}}><div><i style={{ backgroundColor: "#FFCE45" }} className="fas fa-biking"></i></div></Link>}
          {vehicleType !== 'bike' && <div onClick={() => history.push('/dashboard/request/other-drivers', {reqId: id, userClass: createdBy?.userClass, userId: createdBy?.id})}><p style={{ backgroundColor: "#FFCE45", color: "#fff", padding: "5px", borderRadius: "6px", marginBottom: "7px" }}>Responses</p></div>} */}
          
          {/* {vehicleType !== 'bike' ?
          requestStatus === 'active' && 
          <div onClick={() => handleCancel(id)}>
            <p style={{ backgroundColor: "#FF1B00", color: "#fff", padding: "5px", borderRadius: "6px"  }}>Cancel</p>
          </div> : null} */}
        </Td>
      </Tr>
    );
  }
)

const paginate = (pageNumber) => setCurrentPage(pageNumber)

  


  return (
    <div className="all-requests">
      {data?.data?.data.length === 0 ? ( 
        <EmptyState />
      ) : (
        <>
        <PaginatedTable rowTitles={rowTitles} tableBody={tableBody} />
        <Pagination
         postsPerpage={postsPerPage}
         totalPosts={data?.data?.data.length} 
         paginate={paginate} />
        </>
        )}

      {showDeleteModal && (
        <DeleteModal
          text="CONTINUE"
          setShowDeleteModal={setShowDeleteModal}
          requestFunction={cancelRequest}
        />
      )}
      {requestViewShown && <RequestView requestId={requestId} setRequestViewShown={setRequestViewShown} />}
    
      <Modal size={"md"} isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent>
          <ModalHeader>Generate Invoice</ModalHeader>
          <ModalCloseButton _focus={{boxShadow: "none"}} />

          <ModalBody>
            {receiptLoader ? 
              <Flex justifyContent={"center"} mt={6}><Spinner /></Flex>
              :
              <Box ref={inputRef} flexBasis={"360px"} flexShrink="0" padding={{base: "1rem", md:"1.5rem"}} borderRadius={"6px"} border="1px solid #eee" flex="2">
                    <Text mb={1} color="#113E82" fontSize={"1.1rem"} fontWeight={"600"}>Wehaul247 request Invoice</Text>
                    <Divider mb={3} />
                    <Text mb={2}>Pickup Point (Origin): <strong style={{color: "#113E82"}}>{receiptData?.pickUpAddress}</strong></Text>
                    <Text mb={2}>Destination: <strong style={{color: "#113E82"}}>{receiptData?.destinationAddress}</strong></Text>
                    <Text mb={2}>Vehicle Type: <strong style={{color: "#113E82"}}>{receiptData?.vehicleType}</strong></Text>
                    <Text mb={2}>Sender's Name: <strong style={{color: "#113E82"}}>{receiptData?.createdBy?.name}</strong></Text>
                    <Text mb={2}>Sender's Phone: <strong style={{color: "#113E82"}}>{receiptData?.createdBy?.phoneNumber}</strong></Text>
                    <Text mb={2}>Receiver's Name: <strong style={{color: "#113E82"}}>{receiptData?.receiverDetails?.name}</strong></Text>
                    <Text mb={2}>Receiver's Phone: <strong style={{color: "#113E82"}}>{receiptData?.receiverDetails?.phone}</strong></Text>
                    <Text mb={2}>Pickup Date: <strong style={{color: "#113E82"}}>{receiptData?.pickUpDate}</strong></Text>
                    <Text mb={2}>Pickup Time: <strong style={{color: "#113E82"}}>{receiptData?.pickUpTime}</strong></Text>
                    {/* <Text mb={2}>Price: <strong style={{color: "#113E82"}}>₦{receiptData?.FeeWithCommission.toLocaleString()}</strong></Text> */}
                    <Text mb={2}>Item(s): <strong style={{color: "#113E82"}}>{receiptData?.requestTitle}</strong></Text>
                    <Text mb={3}>Item Description: <strong style={{color: "#113E82"}}>{receiptData?.goodsDescription}</strong></Text>
                    <Divider />
                    <Flex mt={3} justifyContent={"center"}>
                      <Image width={"15%"} src={Logo} />
                    </Flex>
                    {/* <Box pt={4}>
                        <Text fontWeight={"500"} color="#37AB78">Driver Information</Text>
                        <Flex gap="1rem" alignItems={"center"} mt="3">
                            <Avatar size={"lg"} name="Juwon Daniels" src={receiptData?.acceptedBy?.profilePicture} />
                            <Box>
                            <Text mb={1}><strong style={{color: "#113E82"}}>{receiptData?.acceptedBy?.name}</strong></Text>
                            <Text mb={1}><strong style={{color: "#113E82"}}>{receiptData?.acceptedBy?.phoneNumber}</strong></Text>
                            <Text><strong style={{color: "#113E82"}}>{receiptData?.acceptedBy?.email}</strong></Text>
                            <Badge colorScheme={"green"}>Verified</Badge>
                            </Box>
                        </Flex>
                    </Box> */}
                </Box>
            }
          </ModalBody>

          <ModalFooter mt={8}>
            <Button variant='ghost' onClick={onClose}>Close</Button>
            <Button colorScheme='blue' mr={3} onClick={printDocument}>
              Download
            </Button>
          </ModalFooter>
        </ModalContent>
      </Modal>
    </div>
  );
};

export default AllRequests;
