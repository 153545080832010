import {baseUrl, devUrl} from '../config.json';
import axios from 'axios';
import { toast } from 'react-toastify'
import CustomToast from '../components/customToast/customToast';
import { useDispatch } from 'react-redux';
import { userActionTypes } from '../redux/constants/userActionTypes';

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
       }
}


export async function getAllRequests (){
   return axios.get(`${devUrl}/admin/request/retrieve-requests`, config)
}

export async function AnalyseExistingStack () {
   return axios.patch()
}

export async function makeBikeRequest (formData, history) {
   if(!localStorage.BIKE_DRIVERS_DATA) {
      try {
         const { data } = await axios.post(`${baseUrl}/admin/request/make-bike-request`, formData, config);
             toast.success(data.data.message, {
                position: toast.POSITION.TOP_CENTER
             })
             console.log(data.data)
             localStorage.setItem("BIKE_DRIVERS_DATA", JSON.stringify(data?.data?.qualifiedBikeRiders))
             localStorage.removeItem('asibikdri')
             history.push('/dashboard/request/drivers', {requestData: data?.data})
       } catch(error) {
          if(error) {
             toast.error(error.response.data.message, {
                position: toast.POSITION.TOP_CENTER
             })
          }
       }
   } else {
      // *useDispatch({type: userActionTypes.BIKEREQLOADER, payload: false})
      toast.warn(CustomToast,{
         position: toast.POSITION.TOP_CENTER
      })
   }
   
}
export async function makeTruckRequest (formData, history) {
   try {
     const res = await axios.post(`${baseUrl}/admin/request/make-truck-request`, formData, config);
     toast.success(res.data.message, {
      position: toast.POSITION.TOP_CENTER
   })
   console.log(res.data)
   window.location.href = '/dashboard/request'
   } catch(error) {
      if(error) {
         toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER
         })
      }
   }
}

export async function makeOtherRequest (formData, history) {
   try {
     const res = await axios.post(`${baseUrl}/admin/request/make-other-request`, formData, config);
     toast.success(res.data.message, {
      position: toast.POSITION.TOP_CENTER
   })
       console.log(res.data.message)
       window.location.href = '/dashboard/request'
   } catch(error) {
      if(error) {
         toast.error(error.response.data.message, {
            position: toast.POSITION.TOP_CENTER
         })
      }
   }
}

export async function cancelRequest(formData, id) {
   try {
      const res = await axios.put(`${baseUrl}/admin/request/${id}/cancel-request`, formData, config);
      toast.success(res.data.message, {
       position: toast.POSITION.TOP_CENTER
    })
        console.log(res.data.message)
      window.location.reload()
    } catch(error) {
       if(error) {
          toast.error(error.response.data.message, {
             position: toast.POSITION.TOP_CENTER
          })
       }
    }
}

export async function availableDrivers({reqId}) {
   try {
     const { data } = await axios.get(`${baseUrl}/admin/request/${reqId}/top-10-drivers`, config)
     console.log(data.data)
     toast.success(data.data.message, {
      position: toast.POSITION.TOP_CENTER
     })
   } catch (error) {
      toast.error(error?.response?.data?.message, {
         position: toast.POSITION.TOP_CENTER
      })
   }
}