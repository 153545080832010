import React, { useState } from 'react';
import './requestList.scss';
import { useHistory, Link } from 'react-router-dom';
import AllRequestdata from '../../../static/allRequest';
import AllRequests from './all-requests/allRequests';
import ActiveRequests from './active-requests/activeRequests';
import CancelledRequests from './cancelled-requests/cancelledRequest';
import { useQuery } from 'react-query';
import { getAllRequests } from '../../../services/requestServices'
import Loader from '../../loader/loader';
import AssignedRequests from './assigned-requests/assignedRequests';
import { ExportJsonCsv } from 'react-export-json-csv';
import { Box, Button, Flex, FormControl, FormLabel, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay, Spinner, Text, useDisclosure } from '@chakra-ui/react';
import axios from 'axios';
import { devUrl } from "../../../config.json"
import { toast } from 'react-toastify';

const config = {
    headers: {
       "Authorization": `Bearer ${localStorage.WEHAUL_ADMIN_TOKEN}`
       }
}

const RequestList = (props) => {
    const [page, setPage] = useState('all');
    const history = useHistory();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const [loading, setLoading] = useState(false);

    const [phone ,setPhone] = useState("");
    const [details, setDetails] = useState(null);
    const [empty, setEmpty] = useState(false);
    const [errorMessage, setErrorMessage] = useState("")

    const { data, error, isLoading} = useQuery('getRequests', getAllRequests);
    if(isLoading) {
        console.log('data is loading')
    }else if(data) {
        console.log('getRequests', data.data.data)
    } else {
        console.log(error)
    }

    const checkLead = async(e) => {
        e.preventDefault()
        setLoading(true)

        try {
            const {data} = await axios.get(`${devUrl}/admin/request/getUserDetails?phoneNumber=${phone}`, config)
            console.log(data)
            setDetails(data?.data)
            setEmpty(false)
        } catch (error) {
            // console.log(error?.response?.data?.message)
            // toast.error(error?.response?.data?.message)
            setErrorMessage(error?.response?.data?.message)
            setEmpty(true)
        } finally {
            setLoading(false)
        }
    }

    const handleCreate = () => {
        navigator.clipboard.writeText(phone)
        window.location.href ="/dashboard/leads"
    }
    
    const headers = [
        {
          key: 'pickUpAddress',
          name: 'Pickup Address',
        },
        {
          key: 'destinationAddress',
          name: 'Destination Address',
        },
        {
          key: 'isExpress',
          name: 'Express Delivery',
        },
        {
            key: 'pickUpDate',
            name: 'Pickup Date'
        },
        {
            key: 'pickUpTime',
            name: 'Pickup Time'
        },
        {
            key: 'requestStatus',
            name: 'Request Status'
        },
        {
            key: 'requestTitle',
            name: 'Request Title'
        },
        {
            key: 'vehicleType',
            name: 'Vehicle Type'
        }
      ]

    return ( 
        <div className="request-list">
            <div className="heading">
                <div className="left-items">
                {/* <i onClick={() => window.location.href = '/dashboard'} className="fas fa-home"></i> */}
                    <div className="title"><span>Requests</span></div>
                    <p className="request-count">{data?.data?.data.length}</p>
                    {/* <p className="export-btn">Export</p> */}
                </div>
                <Flex gap="1rem" alignItems={"center"} className="right-items">
                
                {/* <ExportJsonCsv headers={headers} items={data?.data?.data}>Export</ExportJsonCsv> */}
                <p onClick={() => onOpen()} className='add-action'><i className="fas fa-plus"></i>Request New Trip</p>
                {/* <Link style={{textDecoration:'none'}} to='/dashboard/request/make-request'></Link> */}
                </Flex>
            </div>

            <div className="nav">
                <p onClick={() => setPage('all')} className={page === 'all' ? 'active-all nav-items' : 'nav-items'}>ALL REQUESTS</p>
                <p onClick={() => setPage('active')} className={page === 'active' ? 'active-active nav-items' : 'nav-items'}>ACTIVE REQUESTS</p>
                <p onClick={() => setPage('assigned')} className={page === 'assigned' ? 'active-assigned nav-items' : 'nav-items'}>ASSIGNED REQUESTS</p>
                <p onClick={() => setPage('cancelled')} className={page === 'cancelled' ? 'active-cancelled nav-items' : 'nav-items'}>CANCELLED REQUESTS</p>
            </div>



            {isLoading ? <Flex marginTop={"3rem"} justifyContent={"center"}><Spinner size={"lg"} /></Flex> : page === 'all' && <AllRequests />}
            {!isLoading && page === 'active' && <ActiveRequests />}
            {!isLoading && page === 'assigned' && <AssignedRequests />}
            {!isLoading && page === 'cancelled' && <CancelledRequests />}

    <Modal isCentered isOpen={isOpen} onClose={onClose}>
        <ModalOverlay />
        <ModalContent margin={"0 .5rem"}>
        <ModalHeader color="#113E82">Create Request</ModalHeader>
        <ModalCloseButton _focus={{boxShadow: "none"}} />
        <ModalBody>
            <form onSubmit={checkLead}>
                <FormControl>
                    <FormLabel>Check Phone Number</FormLabel>
                    <Input onChange={e => setPhone(e.target.value)} type="tel" placeholder="Enter Lead Phone Number" minLength={11} maxLength={11} _placeholder={{fontSize: ".9rem"}} />
                </FormControl>

                {empty && 
                    <Box mt={3} border="1px dashed lightgray" borderRadius={"5px"} padding=".7rem">
                        <Text padding={".4rem .6rem"} borderRadius="4px" bg="rgba(220, 20, 60, .2)">{errorMessage}</Text>
                        <Flex justifyContent={"flex-end"}>
                            <Button onClick={handleCreate} marginTop={"2rem"} type="submit" fontSize={"14px"} colorScheme={"blue"}>Copy number and create as Lead</Button>
                        </Flex>
                    </Box>
                }

                {details !== null && 
                    <Box mt={3} border="1px dashed lightgray" borderRadius={"5px"} padding=".7rem">
                        <Flex mb={2} gap="1rem" alignItems={"center"}>
                            <Text>Name:</Text>
                            <Text color={"#113E82"} fontWeight="500">{details?.name}</Text>
                        </Flex>
                        <Flex mb={2} gap="1rem" alignItems={"center"}>
                            <Text>Email:</Text>
                            <Text color={"#113E82"} fontWeight="500">{details?.email}</Text>
                        </Flex>
                        <Flex mb={2} gap="1rem" alignItems={"center"}>
                            <Text>Phone:</Text>
                            <Text color={"#113E82"} fontWeight="500">{details?.phoneNumber}</Text>
                        </Flex>
                        <Flex mb={2} gap="1rem" alignItems={"center"}>
                            <Text>User Class:</Text>
                            <Text color={"#113E82"} fontWeight="500">{details?.userClass}</Text>
                        </Flex>
                        <Flex justifyContent={"flex-end"}>
                        <Link to={{pathname: "/dashboard/request/make-request", state: {name: details?.name, phoneNumber: details?.phoneNumber, id: details?._id}}}><Button marginTop={"2rem"} type="submit" fontSize={"14px"} colorScheme={"blue"}>{loading ? <Spinner /> : "Proceed To Request"}</Button></Link>
                        </Flex>
                    </Box>
                    }

                <Flex pb={3} justifyContent={"flex-end"}>
                    <Button marginTop={"4rem"} type="submit" fontSize={"14px"} colorScheme={"blue"}>{loading ? <Spinner /> : "Check"}</Button>
                </Flex>
            </form>
            </ModalBody>
        </ModalContent>
        </Modal>
        </div>
     );
}
 
export default RequestList;