import React, { useState } from 'react';
import './dashboard.scss';
import Sidebar from '../../components/sidebar/sidebar';
import { BrowserRouter, Route, Switch, useRouteMatch } from 'react-router-dom';
import DashboardLanding from '../../components/dashboard-components/dashboardLanding';
import Header from '../../components/header/header';
import Request from '../../components/request-components/request';
import Customers from '../../components/customers-components/customers';
import Trips from '../../components/trip-components/trips';
import Wallet from '../../components/wallet-components/wallet';
import Settings from '../settings/settings';
import Notifications from '../../components/notifications/notifications';
import LiveTracking from '../../components/live-tracking/liveTracking';
import SidebarCopy from '../../components/sidebarCopy/sidebarCopy';
import AutoComplete from '../../components/autoComplete/autocomplete';
import Ticket from '../ticket/ticket';
import TicketView from '../ticket/ticketView/ticketView';
import UnverifiedBanner from '../../components/unVerifiedBanner/unverifiedBanner';
import { useSelector } from 'react-redux'
import BetaBanner from '../unVerifiedBanner/unverifiedBanner';
import Drivers from '../drivers/drivers';
import Enterprise from '../enterprise/enterprise';
import Routes from '../routes/routes';
import RouteNew from '../routes/routeNew';
import Vehicles from '../vehicles/vehicles';
import Merchant from '../merchant/merchant';
import Referrals from '../referrals/referrals';
import OffDrivers from '../../components/offlineDrivers/offDrivers';
import ManagePassword from '../managePassword/managePassword';
import Profile from '../drivers/profile/profile';
import Profilee from '../enterprise/profile/profile';
import Admins from '../admins/admins';
import Sms from '../sms/sms';
import Coupon from '../coupon/coupon';
import Country from '../country/country';
import Leads from '../leads/leads';

const Dashboard = () => {
    const { path, url }= useRouteMatch();

    const [navOpen, setNavOpen] = useState(true);
    const [mobileNavOpen, setMobileNavOpen] = useState(false)

    const [veriBanner, setVeriBanner] = useState(false);

    const currentUser = useSelector(({userData}) => userData.currentUser)
    console.log(currentUser?.data)

    const {name, walletBalance} = currentUser?.data


    // const navState = useSelector(({uiState}) => uiState.state)
    // console.log('hi', navState)
    
    return (   
        <div className="dashboard">
            <div className="left">
               <Sidebar navOpen={navOpen} />
               {mobileNavOpen && <SidebarCopy setMobileNavOpen={setMobileNavOpen} />}
            </div>
            
            <div className="right" id={navOpen ? 'reduce' : 'full'}>

                <div className="header">
                    <Header setMobileNavOpen={setMobileNavOpen} setNavOpen={setNavOpen} navOpen={navOpen} />
                </div>
                

                <Switch>
                   <Route exact path={path} component={DashboardLanding} />
                   <Route excat path={`${path}/request`} component={Request} />
                   <Route excat path={`${path}/drivers`} component={Drivers} />
                   <Route excat path={`${path}/admins`} component={Admins} />
                   <Route excat path={`${path}/sms`} component={Sms} />
                   <Route excat path={`${path}/view-driver`} component={Profile} />
                   <Route exact path={`${path}/customers`} component={Customers} />
                   <Route exact path={`${path}/offline`} component={OffDrivers} />
                   <Route exact path={`${path}/enterprise`} component={Enterprise} />
                   <Route exact path={`${path}/view-enterprise`} component={Profilee} />
                   <Route exact path={`${path}/merchant`} component={Merchant} />
                   <Route exact path={`${path}/trips`} component={Trips} />
                   <Route exact path={`${path}/finance`} component={Wallet} />
                   <Route exact path={`${path}/coupon`} component={Coupon} />
                   <Route exact path={`${path}/routes`} component={Routes} />
                   <Route exact path={`${path}/referrals`} component={Referrals} />
                   <Route exact path={`${path}/manage-password`} component={ManagePassword} />
                   {/* <Route exact path={`${path}/chat`} component={ChatPage} /> */}
                   <Route exact path={`${path}/settings`} component={Settings} />
                   {/* <Route exact path={`${path}/vehicles`} component={Notifications} /> */}
                   <Route exact path={`${path}/live-tracking`} component={LiveTracking} />
                   {/* <Route exact path={`${path}/test`} component={AutoComplete} /> */}
                   <Route exact path={`${path}/ticket`} component={Ticket} />
                   <Route exact path={`${path}/ticket/:ticketId`} component={TicketView} />
                   <Route exact path={`${path}/create-route`} component={RouteNew} />
                   <Route exact path={`${path}/vehicles`} component={Vehicles} />
                   <Route exact path={`${path}/countries`} component={Country} />
                   <Route exact path={`${path}/leads`} component={Leads} />
                </Switch>

            </div>
        </div> 
    );
};

export default Dashboard;